import React, { useRef, useState, useEffect } from "react";
import styles from "./PatientView.module.css";
import { Button } from 'react-bootstrap';
import CollapsibleSection from './CollapsibleSectionPatientView';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faHeart, faFileCircleCheck, faFileInvoiceDollar, faBoltLightning, faStickyNote, faFileUpload, faHeadset, faPhone, faMobile, faVideo, faMoneyCheckDollar, faMagnifyingGlassDollar, faPrescription } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import { doc, deleteDoc, setDoc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { auth, db } from "../../firebase";
import PatientFilesEligibility from "./Billing/PatientFilesEligibility";
import PatientFilesClaimStatus from "./Billing/PatientFilesClaimStatus";
import PatientFilesClaimsSend from "./Billing/PatientFilesClaim";
import Zoom from "./Zoom";
import Notes from './Notes/Notes'
import Files from './Files';
import EditPatientPage from "./EditPatientPage";
import PatientDataDownload from "./PatientDataDownload";
import Encounters from "./Encounters/Encounters";
import EncountersView from "./Encounters/EncountersView";
import useUID from '../General/useUID'
import ClaimsView from "./ClaimsView";
import EligibilityCards from "./EligibilityView";
import MedicalHistory from './MedicalHistory'
import PatientFlag from "./PatientFlags";
import { useNavigate, useParams } from "react-router-dom";
import InstantEligibility from "./InstantEligibility";
import PatientFileTimer from "./PatientFileTimer";
import AddBPDeviceComponent from "./AddTenovi";
import BPReadingCards from "./BPReadingsCards";
import { SHA256 } from "crypto-js";

function PatientView({ patientSelected, onDataUpdated }) {
  const [openSections, setOpenSections] = useState([]);
  const [isEditing, setisEditing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editedPatientData, setEditedPatientData] = useState(null);
  const [showPatientFilesEligibility, setShowPatientFilesEligibility] = useState(false);
  const [showPatientFilesClaimStatus, setShowPatientFilesClaimStatus] = useState(false);
  const [showPatientFilesClaims, setShowPatientFilesClaims] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [showEncounters, setShowEncounters] = useState(false);
  const [hasEncounters, setHasEncounters] = useState(false);
  const [showTools, setShowTools] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [uid, subUserUID] = useUID();
  const { PatientId } = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const isEncountersPath = window.location.pathname.includes("/encounters");

  //   // If "encounters" is in the URL and Encounters component is not open, navigate to the correct URL
  //   if (isEncountersPath && !showEncounters) {
  //     setShowEncounters(true)
  //   }
  // }, [showEncounters, navigate]);

  if (!patientSelected) {
    return <div></div>;
  }

  const patientData = patientSelected.data;
  const { patient } = patientData;
console.log(patientData)
  const firstName = patient ? patient.firstName : patientData.subfirstName;
  const middleName = patient ? patient.middleName : patientData.submiddleName;
  const lastName = patient ? patient.lastName : patientData.sublastName;
  const memberid = patientData.payers?.memberId || patientData.eligibility?.submemberId;
  const address1 = patient?.address?.address1 || patientData.eligibility?.subAddress1;
  const address2 = patient?.address?.address2 || patientData.eligibility?.subAddress2;
  const city = patient?.address?.city || patientData.eligibility?.subCity;
  const state = patient?.address?.state || patientData.eligibility?.subState;
  const zip = patient?.address?.zip;
  const email = patient?.email;
  const phone = patient?.phone;
  const tradingPartnerName = patientData.payers?.name
  const dob = patient
    ? formatDate(patient.dob || patient.eligibility?.dobFormatted)
    : formatDate(patientData.eligibility?.subdob || patientData.eligibility?.subdobFormatted);
  const gender = patient
    ? patient.gender || patient.genderFormatted
    : patientData.eligibility?.subgender || patientData.eligibility?.subgenderFormatted;
    const flag = patientData?.flag;

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }

    let date;

    // Check if the dateString is in the format YYYYMMDD
    if (/^\d{8}$/.test(dateString)) {
      const year = parseInt(dateString.slice(0, 4));
      const month = parseInt(dateString.slice(4, 6)) - 1; // Months are zero-based
      const day = parseInt(dateString.slice(6, 8));
      date = new Date(year, month, day);
    } else {
      date = new Date(dateString);
    }

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const handleEligibility = () => {
    setShowPatientFilesEligibility(!showPatientFilesEligibility);
  };


  const handleClaimStatus = () => {
    setShowPatientFilesClaimStatus(!showPatientFilesClaimStatus);
  };

  const handleSendClaim = () => {
    setShowPatientFilesClaims(!showPatientFilesClaims);
  };


  const handleZoom = () => {
    setShowZoom(!showZoom);
  };

  const handleNotes = () => {
    setShowNotes(!showNotes);
  };
  const handleFiles = () => {
    setShowFiles(!showFiles);
  };

  function hashDocIdToNumber(docId) {
    const hash = SHA256(docId).toString(); // This gives you the hash as a hex string
    const largeNumber = parseInt(hash.slice(0, 15), 16); // Convert the first 15 characters to an integer
    return largeNumber % 1000000000; // Use modulo to limit it to 9 digits
  }
  const patientMRN = hashDocIdToNumber(patientSelected.id);


  const handleEncounters = () => {

    // // Get the patient ID from the URL
    // const { PatientId } = useParams();

    // const newUrl = `/patients/${PatientId}${showEncounters ? "/encounters" : ""}`;

    // // Use navigate to update the URL
    // navigate(newUrl);

    setShowEncounters(!showEncounters);
  };


  function formatGender(gender) {
    if (!gender) {
      return "";
    }

    const genderLower = gender.toLowerCase();
    if (genderLower === "m" || genderLower === "male") {
      return "Male";
    } else if (genderLower === "f" || genderLower === "female") {
      return "Female";
    } else {
      return "";
    }
  }

  //autistics capitalization function
  function capitalizeName(name) {
    if (!name) {
      return "";
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  const handleShowEditPage = () => {
    if (isEditing) {
      setisEditing(false);
    } else {
      setisEditing(true);
    }
  };

  const handleCloseEditPage = () => {
    setisEditing(false); 
  };
  

  const handleDelete = () => {
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    // Mark the patient as "deleted" in Firestore
    const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);

    // Update the "deleted" field of the document
    const test = await updateDoc(patientRef, {
      deleted: true
    });
    onDataUpdated();

    // Close the confirmation modal
    setShowConfirmModal(false);
    setisEditing(false);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleSavePatientData = async (editedData) => {
    const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
    try {
      await setDoc(patientRef, editedData);
      onDataUpdated(); // Notify the parent component about the update
    } catch (error) {
      console.error("Error updating patient data:", error);
    }
  };

  const handleToggleTools = () => {
    setShowTools(!showTools);
  }

  const handleDataUpdated = () => {
    onDataUpdated(); // Notify the parent component about the update
  };

  return (

    <div className={styles.contentContainer}>

      {showPatientFilesEligibility && (
        <div>
          <PatientFilesEligibility
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            tradingPartnerName={tradingPartnerName}
            onClose={() => setShowPatientFilesEligibility(false)}
          />
        </div>
      )}
      {showPatientFilesClaimStatus && (
        <div>
          <PatientFilesClaimStatus
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            tradingPartnerName={tradingPartnerName}
            onClose={() => setShowPatientFilesClaimStatus(false)}
          />
        </div>
      )}
      {showPatientFilesClaims && (
        <div>
          <PatientFilesClaimsSend
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zip={zip}
            tradingPartnerName={tradingPartnerName}
            onClose={() => setShowPatientFilesClaims(false)}
          />
        </div>
      )}
      {showZoom && (
        <div className={styles.overlay}>
          <div className={styles.modalBox}>
            <Zoom
              email={email}
              phone={phone}
              onClose={() => setShowZoom(false)}
            />
          </div>
        </div>
      )}
      {showNotes && (
        <div className={styles.overlay}>
          <div className={styles.modalBox}>
            <Notes
              patientId={patientSelected.id}
              onClose={() => setShowNotes(false)}
            />
          </div>
        </div>
      )}
      {showFiles && (
        <div className={styles.overlay}>
          <div className={styles.modalBox}>
            <Files
              patientId={patientSelected.id}
              onClose={() => setShowFiles(false)}
            />
          </div>
        </div>
      )}
      {showEncounters && (
        <Encounters
          patientId={patientSelected.id}
          onClose={() => setShowEncounters(false)}
        />
      )}

      <Modal
        isOpen={showConfirmModal}
        onRequestClose={cancelDelete}
        className={styles.confirmModal}
        overlayClassName={styles.confirmModalOverlay}
      >
        <h2>Delete "{firstName} {lastName}"?</h2>
        <p>Are you sure you want to delete the selected patient?</p>
        <div className={styles.confirmButtons}>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </div>
      </Modal>
      <div className={styles.topBar}>
        <span className={styles.editText} onClick={handleShowEditPage}>Edit</span>
        <PatientFlag 
        patientSelected={patientSelected}
        onDataUpdated={handleDataUpdated}
        />
        <PatientDataDownload patientSelected={patientSelected} />
        <PatientFileTimer patientSelected={patientSelected} />
      </div>

      {isEditing && (

        <EditPatientPage
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zip={zip}
            tradingPartnerName={tradingPartnerName}
          patientData={editedPatientData || patientData}
          handleSave={handleSavePatientData}
          handleShowEditPage={handleShowEditPage}
          handleDelete={handleDelete}
          patientSelected={patientSelected}
          onClose={handleCloseEditPage}
        />
      )}

      <div className="patientDataContainer">
        <div className="titleBox">
          <h2 className="patientDataTitle">
            <span className="initialsCircleContainer">
              <span className="initialsCircle">
                {capitalizeName(firstName).charAt(0)}{capitalizeName(lastName).charAt(0)}
              </span>
              <span className={styles.name}>
                {capitalizeName(firstName)}
                <span>{" "}</span>
                {capitalizeName(lastName)}
              </span>
            </span>
            <br />
            {dob && (
              <div className="dobandgender">
                <span style={{ fontSize: "24px", fontWeight: "lighter" }}>
                  {dob}
                </span>
                {gender && (
                  <span style={{ marginLeft: "50px", fontSize: "24px", fontWeight: "lighter" }}>
                    {formatGender(gender)}
                  </span>
                )}

              </div>
            )}
          </h2>
        </div>
        <div className="actions">
          <div className="firstRow">
            <div className="action-button-container">

              {/* 
              <button onClick={handleToggleContact}>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faPrescription} size="lg" style={{ height: '30px' }} />
                </div>
                <div className="text-container">
                  Prescription
                </div>
              </button> */}

              <button onClick={handleEncounters}>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faHeart} size="lg" style={{ height: '30px' }} />
                </div>
                <div className="text-container">
                  Encounter
                </div>
              </button>

              {!subUserUID && (
                <div>
                  <button 
                      onClick={handleToggleTools}
                      className={`${showTools ? 'selected' : ''}`}
                    >
                      <div className="icon-container">
                        <FontAwesomeIcon icon={faMoneyCheckDollar} size="lg" style={{ height: '30px' }} />
                      </div>
                      <div className="text-container">
                        Billing
                      </div>
                    </button>


                </div>
              )}
            </div>

            {showTools && (

              <div className="secondRow">
                <div className="action-button-container">
                  <>
                    <button onClick={handleSendClaim}>
                      <div className="icon-container">
                        <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" style={{ height: '30px' }} />
                      </div>
                      <div className="text-container">
                        Send Claim
                      </div>
                    </button>

                    <button onClick={handleEligibility}>
                      <div className="icon-container">
                        <FontAwesomeIcon icon={faMagnifyingGlassDollar} size="lg" style={{ height: '30px' }} />
                      </div>
                      <div className="text-container">
                        Eligibility Search
                      </div>
                    </button>

                    <InstantEligibility patientId={patientSelected.id}/>
                  </>
                </div>
              </div>
            )}

            <div className="secondRow">
              <div className="action-button-container">
                <button onClick={handleZoom}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faVideo} size="lg" style={{ height: '30px' }} />
                  </div>
                  <div className="text-container">
                    Zoom
                  </div>
                </button>
                <button onClick={handleNotes}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faStickyNote} size="lg" style={{ height: '30px' }} />
                  </div>
                  <div className="text-container">
                    Notes
                  </div>
                </button>
                <button onClick={handleFiles}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faFileUpload} size="lg" style={{ height: '30px' }} />
                  </div>
                  <div className="text-container">
                    Files
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.patientInfocontainer}>
            <div className={styles.payerSummary}>
              <div style={{ display: "flex" }}>
              {patientData && patientData.payers?.name && (
                  <p className={styles.insuranceName}>
                    {patientData.payers?.name}
                    {(flag === "Eligible" || flag === "Ineligible") &&
                      ` | ${flag}`}
                  </p>
                )}
              </div>
              {patientData && patientData.payers?.memberId && (
                <p className={styles.memberId}>
                  Member ID: {patientData.payers?.memberId}
                </p>
              )}
            </div>

               {/* Secondary Payer Display */}
            {patientData.payers?.secondaryPayer && (
              <div className={styles.payerSummary} style={{ marginTop: "10px" }}>
                <div style={{ display: "flex" }}>
                  <p className={styles.insuranceName}>
                    {patientData.payers.secondaryPayer.name}
                    {/* Add any additional flags or indicators as needed */}
                  </p>
                </div>
                <p className={styles.memberId}>
                  Member ID: {patientData.payers.secondaryPayer.memberId}
                </p>
              </div>
            )}


            <AddBPDeviceComponent patientId={patientSelected.id} uid={uid}/>
            <BPReadingCards patientId={patientSelected.id} uid={uid} />

            <div className={styles.collapsibleContainer}>
              <div>
                

                  {patientData.patient?.firstName && (
                    <p>
                      <span style={{ opacity: 0.75 }}>First Name: </span>
                      <span className="opacity-100">{patientData.patient?.firstName}</span>
                    </p>
                  )}
                  {patientData.patient?.lastName && (
                    <p>
                      <span style={{ opacity: 0.75 }}>Last Name: </span>
                      <span className="opacity-100">{patientData.patient?.lastName}</span>
                    </p>
                  )}
                  {(patientData.patient?.dob || patientData.patient?.dobFormatted) && (
                    <p>
                      <span style={{ opacity: 0.75 }}>Date of Birth: </span>
                      <span className="opacity-100">
                        {formatDate(patientData.patient.dob || patientData.patient.dobFormatted)}
                      </span>
                    </p>
                  )}
                  {(patientData.patient.gender || patientData.patient?.genderFormatted) && (
                    <p>
                      <span style={{ opacity: 0.75 }}>Gender: </span>
                      <span className="opacity-100">
                        {formatGender(patientData.patient.gender || patientData.patient.genderFormatted)}
                      </span>
                    </p>
                  )}
                  {patientData.patient?.address?.address1 && (
                    <p>
                      <span style={{ opacity: 0.75 }}>Address: </span>
                      <span  >
                        {patientData.patient.address.address1}
                        {patientData.patient.address.address2 ? `, ${patientData.patient.address.address2}` : ''}
                        , {patientData.patient.address.city}, {patientData.patient.address.state} {patientData.patient.address.zip}
                      </span>
                    </p>
                  )}
                  {patientData.patient?.email && (
                    <p>
                      <span style={{ opacity: 0.75 }}>Email: </span>
                      <span className="opacity-100">{patientData.patient?.email}</span>
                    </p>
                  )}
                  {patientData.patient?.phone && (
                    <p>
                      <span style={{ opacity: 0.75 }}>Phone: </span>
                      <span className="opacity-100">{patientData.patient?.phone}</span>
                    </p>
                  )}
                  {patientData.patient?.primaryDoctor && (
                    <p>
                      <span style={{ opacity: 0.75 }}>Primary Doctor: </span>
                      <span className="opacity-100">{patientData.patient?.primaryDoctor}</span>
                    </p>
                  )}

                {patientMRN && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Medical Record Number: </span>
                    <span className="opacity-100">
                      {patientMRN}
                    </span>
                  </p>
                )}

                {patientData.type && (
                    <p>
                        <span style={{ opacity: 0.75 }}>Patient Type: </span>
                        <span className="opacity-100">{patientData.type}</span>
                    </p>
                )}

                      {patientData.provider && (
                          <>
                              <p>
                                  <span style={{ opacity: 0.75 }}>Primary Care Provider: </span>
                                  <span className="opacity-100">
                                      {patientData.provider.name}
                                  </span>
                              </p>
                              <p>
                                  <span style={{ opacity: 0.75 }}>PCP Phone: </span>
                                  <span className="opacity-100">
                                      {patientData.provider.phone}
                                  </span>
                              </p>
                              <p>
                                  <span style={{ opacity: 0.75 }}>PCP Address: </span>
                                  <span className="opacity-100">
                                      {patientData.provider.address}
                                  </span>
                              </p>
                          </>
                      )}

               
                  <EncountersView patientId={patientSelected.id} />

                  
                  <EligibilityCards patientId={patientSelected.id} />


                
                  <MedicalHistory patientId={patientSelected.id} />

                  <ClaimsView patientId={patientSelected.id} />

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientView;