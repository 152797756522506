import React, { useState, useEffect, useCallback } from 'react';
import styles from '../Claims/Claims.css';
import { db, auth } from "../../firebase";
import {
  startAfter,
  limit,
  collection,
  getDocs,
  addDoc,
  orderBy,
  doc,
  query,
  where,
  deleteDoc
} from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'; // For regular (outline) heart
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons'; // For solid heart
import useUID from '../General/useUID';
import StateSelector from './StateSelector';
import HexagonSpinner from '../General/Animations/Hexspinner';

function PayerSelection({ onSelect, TradingPartnerName, mode }) {
  const ITEMS_PER_PAGE = 100; // Adjust number of items per page as needed
  const [uid, subUserUID] = useUID();
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [payers, setPayers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [favoritePayers, setFavoritePayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePayers, setHasMorePayers] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const [hasAutoSelected, setHasAutoSelected] = useState(false);

  useEffect(() => {
    if (mode == 'website') fetchData(1);
  }, []);

  useEffect(() => {
    if (uid && mode != 'website') {
      fetchFavoritePayers()
        .then(() => fetchData(1)) // Fetch data after favorites are loaded
        .catch(error => console.error('Error loading data:', error));
    }
  }, [uid]);

  useEffect(() => {
    setPayers(payers => sortPayers(payers));
  }, [favoritePayers]);  

  useEffect(() => {
    if (!searchTerm && !isMounted) {
      setCurrentPage(1);
    }

    searchPayers();
  }, [searchTerm, selectedState]);
  
  useEffect(() => {
    if (currentPage > 1) {
      fetchData(currentPage);
    }
  }, [currentPage]);

  
  useEffect(() => {
    if (TradingPartnerName) {
      autoSelectPayer(TradingPartnerName);
    }
  }, [TradingPartnerName, payers]);

  useEffect(() => {
    setHasAutoSelected(false);
  }, [TradingPartnerName]);


  const fetchData = async (page) => {
    setIsLoading(true);
  
    try {
      let queryRef = collection(db, 'Payers');
  
      // Initial query setup with orderBy
      if (page === 1) {
        queryRef = query(queryRef, orderBy("payerName"), limit(ITEMS_PER_PAGE));
      } else if (lastDoc) {
        // For subsequent pages, start after the last document from the previous fetch
        queryRef = query(queryRef, orderBy("payerName"), startAfter(lastDoc), limit(ITEMS_PER_PAGE));
      }
  
      const querySnapshot = await getDocs(queryRef);
      const payerData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      if (payerData.length > 0) {
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setPayers(prevPayers => sortPayers([...prevPayers, ...payerData]));
      } else {
        setHasMorePayers(false);
      }
    } catch (error) {
      console.error('Error fetching payers:', error);
    }
  
    setIsLoading(false);
  };
  
  const sortPayers = (payersArray) => {
    return payersArray.sort((a, b) => {
      const isAFavorite = favoritePayers.includes(a.payerName);
      const isBFavorite = favoritePayers.includes(b.payerName);
  
      if (isAFavorite && !isBFavorite) return -1;
      if (!isAFavorite && isBFavorite) return 1;
  
      // If neither or both are favorites, sort by payer name
      return a.payerName.localeCompare(b.payerName);
    });
  };
  

 // Add this helper function to batch your favorite payers for the 'in' query
const chunkArray = (array, size) => {
  const result = [];
  const arrayCopy = [...array]; // Clone the array to avoid modifying original
  while (arrayCopy.length) {
    result.push(arrayCopy.splice(0, size));
  }
  return result;
};

const fetchFavoritePayers = async () => {
  try {
    if (!uid) {
      throw new Error('User ID is undefined');
    }
    const userDocRef = doc(db, "users", uid);
    const payersCollectionRef = collection(userDocRef, 'favoritePayers');
    const querySnapshot = await getDocs(payersCollectionRef);
    const fetchedFavoritePayers = querySnapshot.docs.map(doc => doc.data().payerName);

    setFavoritePayers(fetchedFavoritePayers);

    // Now fetch the data for each favorite payer
    const favoritePayersData = [];
    if (fetchedFavoritePayers.length > 0) {
      const batches = chunkArray(fetchedFavoritePayers, 10);
      for (const batch of batches) {
        const q = query(collection(db, 'Payers'), where('payerName', 'in', batch));
        const batchSnapshot = await getDocs(q);
        batchSnapshot.forEach(doc => {
          favoritePayersData.push({ id: doc.id, ...doc.data() });
        });
      }
    }

    // Now merge favoritePayersData into payers
    setPayers(prevPayers => {
      // Add favoritePayersData to prevPayers if they are not already there
      const newPayers = [...prevPayers];
      favoritePayersData.forEach(favPayer => {
        if (!newPayers.some(p => p.id === favPayer.id)) {
          newPayers.push(favPayer);
        }
      });
      return sortPayers(newPayers);
    });
  } catch (error) {
    console.error('Error fetching favorite payers:', error);
  }
};


  const searchPayers = async () => {
    setIsLoading(true);
  
    let conditions = [];
    if (searchTerm) {
      conditions.push(where("payerName", ">=", searchTerm.toUpperCase()));
      conditions.push(where("payerName", "<=", searchTerm.toUpperCase() + '\uf8ff'));
    }
    if (selectedState) {
      conditions.push(where("State", "==", selectedState));
    }
    
    let searchQuery = query(collection(db, "Payers"), ...conditions, limit(ITEMS_PER_PAGE));
  
    try {
      const querySnapshot = await getDocs(searchQuery);
      const searchResults = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      setPayers(searchResults);
      setHasMorePayers(searchResults.length >= ITEMS_PER_PAGE);
  
    } catch (error) {
      console.error('Error searching payers:', error);
    }
  
    setIsLoading(false);
  };
  
  const getIdentifier = (payer) => {
    if (!payer) return null; // Ensure payer is not null
    return mode === 'claims' ? payer.CPID : payer.RealtimePayerID;
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
  
    // Check if it's the right time to load more payers
    if (scrollHeight - scrollTop <= clientHeight + 10 && hasMorePayers) {
      // Only load more if no current loading process is happening
      if (!isLoading) {
        setCurrentPage(prevPage => prevPage + 1);
      }
    }
  };

  const handleSelect = (payer) => {
    const nextPriority = selectedPayers.length + 1;
    const updatedSelectedPayers = [...selectedPayers, { payer, priority: nextPriority }];
    setSelectedPayers(updatedSelectedPayers);
    onSelect(updatedSelectedPayers.map(sp => ({
      name: sp.payer.payerName,
      tradingPartnerId: getIdentifier(sp.payer),
      priority: sp.priority,
    })));
  };

  const handleUnselect = (payer) => {
    const updatedSelectedPayers = selectedPayers.filter(sp => sp.payer !== payer);
    updatedSelectedPayers.forEach((sp, index) => {
      sp.priority = index + 1;
    });
    setSelectedPayers(updatedSelectedPayers);
    onSelect(updatedSelectedPayers.map(sp => ({
      name: sp.payer.payerName,
      tradingPartnerId: getIdentifier(sp.payer),
      priority: sp.priority
    })));
  };

  const handleClick = (payer) => {
    if (mode === "eligibility") {
      const isSelected = selectedPayers.some(sp => sp.payer === payer);
      if (isSelected) {
        handleUnselect(payer);
      } else {
        handleSelect(payer);
      }
    } else if (mode === "claimStatus" || mode === "claims") {
      const payerId = getIdentifier(payer);
      if (selectedPayer && getIdentifier(selectedPayer) === payerId) {
        setSelectedPayer(null);
        onSelect(null);
      } else {
        setSelectedPayer(payer);
        onSelect({ name: payer.payerName, tradingPartnerId: payerId });
      }
    }
  };

    const handleChangeClick = () => {
      setSelectedPayer(null); // Reset the selected payer
    };

    const handleSelectForClaims = (payer) => {
      const payerId = getIdentifier(payer);
      if (selectedPayer && getIdentifier(selectedPayer) === payerId) {
        setSelectedPayer(null);
        onSelect(null);
      } else {
        setSelectedPayer(payer);
        onSelect({ name: payer.payerName, tradingPartnerId: payerId });
      }
    };
  
    const autoSelectPayer = useCallback((TradingPartnerName) => {
      if (hasAutoSelected) return; 
      setIsLoading(true); 
      const foundPayer = payers.find(payer => payer.payerName.toLowerCase() === TradingPartnerName.toLowerCase());
      
      if (foundPayer) {
        if (mode === "eligibility") {
          handleSelect(foundPayer);
        } else if (mode === "claimStatus" || mode === "claims") {
          handleSelectForClaims(foundPayer);
        }
        setHasAutoSelected(true);
      }
      setIsLoading(false); 
    }, [payers, mode, handleSelect, handleSelectForClaims, hasAutoSelected]);

  const modifyFavorite = useCallback(async (payer, add = true) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const payerNameToModify = { payerName: payer.payerName };

      if (add) {
        await addDoc(collection(userDocRef, "favoritePayers"), payerNameToModify);
        setFavoritePayers(prev => [...prev, payer.payerName]); // Updated to store payer names
      } else {
        const payersCollectionRef = collection(userDocRef, 'favoritePayers');
        const q = query(payersCollectionRef, where("payerName", "==", payer.payerName));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (docSnapshot) => {
          await deleteDoc(doc(db, "users", uid, "favoritePayers", docSnapshot.id));
        });

        setFavoritePayers(prev => prev.filter(fp => fp !== payer.payerName)); // Updated to store payer names
      }
    } catch (error) {
      console.error(`Error ${add ? 'adding' : 'removing'} payer to favorites:`, error);
    }
  }, [uid]);


  const filteredPayers = payers
    .filter(payer => {
      // For eligibility or claimStatus mode, include payer only if it has a RealtimePayerID
      if ((mode === "eligibility" || mode === "claimStatus") && !payer.RealtimePayerID) {
        return false;
      }

      // For claims mode, include payer only if it has a CPID and it's a number
      if (mode === "claims" && (typeof payer.CPID !== 'number' || isNaN(payer.CPID))) {
        return false;
      }

      return payer.payerName.toLowerCase();
    })
    .sort((a, b) => {
      // Check if payers are favorite
      const isAFavorite = favoritePayers.map(fp => fp.toUpperCase()).includes(a.payerName.toUpperCase());
      const isBFavorite = favoritePayers.includes(b.payerName);

      if (isAFavorite && !isBFavorite) return -1; // A is favorite, B is not
      if (!isAFavorite && isBFavorite) return 1;  // B is favorite, A is not

      // Additional sorting based on mode
      if (mode === "eligibility") {
        const isASelected = selectedPayers?.some(sp => sp.payer.id === a.id);
        const isBSelected = selectedPayers?.some(sp => sp.payer.id === b.id);
        if (isASelected && !isBSelected) return -1;
        if (!isASelected && isBSelected) return 1;
      }

      // If both or neither are favorites, sort by payer name or any other criteria
      return a.payerName.localeCompare(b.payerName);
    });

    return (
      <div
        className='payerContainer'
        style={{ maxHeight: '20rem', overflowY: 'scroll', margin: "1rem", overflowX: 'hidden' }}
        onScroll={handleScroll}
      >
        {(!selectedPayer || mode === "eligibility") && (
          <div style={{ display: 'flex' }}>
            <input
              type="text"
              placeholder="Search Payers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className='payerSearch'
            />
            <StateSelector selectedState={selectedState} setSelectedState={setSelectedState} />
          </div>
        )}
    
        <table className={styles.table}>
          <thead className='payertableHeader thread'>
            <tr>
              {mode === "eligibility" && <th>Priority</th>}
            </tr>
          </thead>
    
          {(mode !== "eligibility" && selectedPayer) ? (
            <div>
              <p>Selected Payer: {selectedPayer.payerName}</p>
              <button onClick={handleChangeClick}>Change</button>
            </div>
          ) : (
            <tbody>
              {filteredPayers.map((payer, index) => {
                const payerId = getIdentifier(payer);
                const isSelected = mode === "eligibility" || mode === "claimStatus"
                  ? selectedPayers.some(sp => getIdentifier(sp.payer) === payerId)
                  : getIdentifier(selectedPayer) === payerId;

                const isFavorited = favoritePayers.includes(payer.payerName.trim());
                const priority = (selectedPayers || []).find(sp => sp.payer === payer)?.priority || '—';


                return (
                  <tr key={index} onClick={() => handleClick(payer)} className={isSelected ? styles.selected : ''}>
                    <td>
                      <label htmlFor={`payer-${index}`}>{payer.payerName}</label>
                    </td>
                    {/* Additional columns for eligibility mode */}
                    {mode === "eligibility" && (
                      <td>
                        {priority}
                      </td>
                    )}
                    <td>
                      {auth.currentUser && (isFavorited
                        ? <FontAwesomeIcon
                          icon={fasHeart}
                          onClick={(e) => {
                            e.stopPropagation();
                            modifyFavorite(payer, false); // Removing from favorites
                          }}
                        />
                        : <FontAwesomeIcon
                          title="Make a favorite payer"
                          className="faintHeart"
                          icon={farHeart}
                          onClick={(e) => {
                            e.stopPropagation();
                            modifyFavorite(payer, true); // Adding to favorites
                          }}
                        />
                      )}
                    </td>

                  </tr>
                );
              })}
              </tbody>
            )}
      
            {isLoading && (
              <div className="loadingContainer">
                <HexagonSpinner />
              </div>
            )}
          </table>
        </div>
      );
}

export default PayerSelection;